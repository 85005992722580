@import 'colors';

tui-svg,
tui-svg[tuiWrapper][data-appearance='icon'][data-appearance='icon'] {

  color: var(--tui-text-03);

  &.pointer {
    cursor: pointer;
  }

  &.grab {
    cursor: grab;
  }

  &.grabbing {
    cursor: grabbing;
  }

  &:hover {
    color: var(--tui-primary-hover);
  }

  &.not-hover {
    color: var(--tui-text-03);
  }

  &.disabled {
    color: var(--tui-text-03);
    cursor: default;
    opacity: 0.5;
  }

  &.icon-green {
    color: var(--tui-positive);
  }

  &.icon-red {
    color: var(--tui-negative);

    &:hover {
      color: var(--tui-negative-hover);
    }
  }

  &.icon-orange {
    color: var(--tui-support-08);
  }

  &.icon-black {
    color: var(--tui-text-01);
  }

  &.up {
    transform: rotate(0);
  }

  &.down {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(-90deg);
  }

  &.right {
    transform: rotate(90deg);
  }

  &.transition {
    transition: transform 0.3s ease-in-out;
  }
}
