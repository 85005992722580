@common-padding: 24px;
@common-padding-s: 16px;
@common-border-radius: 6px;
@common-input-text-padding: 8px;
@font-size-s: 14px;
.cursor-pointer {
  cursor: pointer;

  &:hover {
    color: var(--tui-primary);
  }
}

.base-icon {
  color: var(--tui-base-08);

  &:hover {
    color: rgba(0, 0, 0, 0.5);;
  }
}


.primary-icon {
  color: var(--tui-primary);

  &:hover {
    color: var(--tui-primary-hover);
  }
}

.delete-icon {
  color: var(--tui-error-fill);
  //margin: 0 10px;

  &:hover {
    color: var(--tui-negative);
  }
}

.horizontal-line {
  border-top: 1px solid rgba(0, 0, 0, 0.32);
  margin: 10px 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.block-button {
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-right: 10px;
  }
}


.sidepage-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .container-context {
    border-bottom: 1px solid var(--tui-base-02);
    padding-bottom: @common-padding;
    padding-left: @common-padding;
    padding-right: @common-padding;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: @common-padding-s;

    tui-input {
      width: 100%;
      min-width: 300px;
    }

    .row-flex {
      width: 100%;
      min-width: 400px;
      gap: @common-padding;
      display: flex;
      align-items: center;
    }


  }

  .pt-common {
    padding-top: @common-padding;
  }

  .content {
    margin: 0 @common-padding;
    display: flex;
    flex-direction: column;
    gap: @common-padding;

    tui-input, tui-select, tui-input-date-time {
      width: 100%;
      min-width: 300px;
    }

    .form-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span:not(tui-input-number) span {
        margin-bottom: @common-input-text-padding;
        color: @text-2;
      }

      &.half {
        width: calc(50% - (@common-padding / 2));
      }
    }

    .row-2-flex {
      width: 100%;
      min-width: 400px;
      gap: @common-padding;
      display: flex;
    }

    //tui-checkbox-labeled {
    //  color: @text-2;
    //}

    .custom-fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: @common-padding-s;

      tui-input {
        min-width: 250px;
      }
    }
  }

  .buttons {
    padding: @common-padding;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: @common-padding-s;
    box-shadow: -24px 1px 24px 0 rgba(155, 146, 146, 0.58);

    button {
      font-size: 14px;
    }
  }
}
